import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getChats as getChatsApi,
    getReadings as getReadingsApi,
    getWritings as getWritingsApi,
    getGroups as getGroupsApi,
    getContacts as getContactsApi,
    getMessages as getMessagesApi,
    addMessage as addMessageApi,
    updateAnswer as updateAnswerApi,
    newDocument as newDocumentApi,
} from "../../helpers/fakebackend_helper";

export const getChats = createAsyncThunk("chats/getChats", async () => {
    try {
        const response = getChatsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getReadings = createAsyncThunk("chats/getReadings", async () => {
    try {
        const response = getReadingsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getWritings = createAsyncThunk("chats/getWritings", async () => {
    try {
        const response = getWritingsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getGroups = createAsyncThunk("chats/getGroups", async () => {
    try {
        const response = getGroupsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getContacts = createAsyncThunk("chats/getContacts", async () => {
    try {
        const response = getContactsApi();
        return response;
    } catch (error) {
        return error;
    }
});

export const getMessages = createAsyncThunk("chats/getMessages", async (roomId:any) => {
    try {
        const response = getMessagesApi(roomId);
        return response;
    } catch (error) {
        return error;
    }
});

export const addMessage = createAsyncThunk("chats/addMessage", async (message:any) => {
    try {
        const response = addMessageApi(message);
        return response;
    } catch (error) {
        return error;
    }
});

export const updateGptAnswer = createAsyncThunk("chats/updateGptAnswer", async (message:any) => {
    try {
        const response = updateAnswerApi(message);
        return response;
    } catch (error) {
        return error;
    }
});

export const getNewDocId = createAsyncThunk("chats/addDocument", async (doctypeId:any) => {
    try {
        // console.log("new chatting............");
        const response = newDocumentApi(doctypeId);
        return response;
    } catch (error) {
        return error;
    }
});