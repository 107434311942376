import { createSlice } from "@reduxjs/toolkit";

import { getChats,getReadings,getWritings,getGroups,getContacts,getMessages,addMessage, updateGptAnswer,getNewDocId} from "./thunk";
import { number } from "yup";

export const initialState = {
    chats: [],
    groups : [],
    contacts:[],
    messages:[],
    newDocid:0,
    error: {}
};

const ChatsSlice = createSlice({
    name: 'ChatsSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getChats.fulfilled, (state: any, action: any) => {
            state.chats = action.payload;
        });
        builder.addCase(getChats.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getReadings.fulfilled, (state: any, action: any) => {
            state.chats = action.payload;
        });
        builder.addCase(getReadings.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getWritings.fulfilled, (state: any, action: any) => {
            state.chats = action.payload;
        });
        builder.addCase(getWritings.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });

        builder.addCase(getGroups.fulfilled, (state: any, action: any) => {
            state.groups = action.payload;
        });
        builder.addCase(getGroups.rejected, (state: any, action: any) => {
            state.groups = action.payload.error || null;
        });

        builder.addCase(getContacts.fulfilled, (state: any, action: any) => {
            state.contacts = action.payload;
        });
        builder.addCase(getContacts.rejected, (state: any, action: any) => {
            state.contacts = action.payload.error || null;
        });

        builder.addCase(getMessages.fulfilled, (state: any, action: any) => {
            state.messages = action.payload;
        });
        builder.addCase(getMessages.rejected, (state: any, action: any) => {
            state.messages = action.payload.error || null;
        });

        builder.addCase(addMessage.fulfilled, (state: any, action: any) => {
            state.messages.push(action.payload);
        });
        builder.addCase(addMessage.rejected, (state: any, action: any) => {
            state.messages = action.payload.error || null;
        });

        // builder.addCase(updateGptAnswer.fulfilled, (state: any, action: any) => {
        //     state.messages.push(action.payload);
        // });
        builder.addCase(updateGptAnswer.fulfilled, (state: any, action: any) => {
            const index = state.messages.findIndex(message => message.id === action.payload.id);
            if (index !== -1) {
                state.messages[index] = { ...state.messages[index], ...action.payload };
            }
        });
        builder.addCase(updateGptAnswer.rejected, (state: any, action: any) => {
            state.messages = action.payload.error || null;
        });

        builder.addCase(getNewDocId.fulfilled, (state: any, action: any) => {
            state.newDocid = action.payload;
            console.log("================",state.newDocid);
        });
        builder.addCase(getNewDocId.rejected, (state: any, action: any) => {
            state.error = action.payload.error || null;
        });
    }
})

export default ChatsSlice.reducer;