import React from 'react'

import {
  Container,
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  DropdownItem,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Table,
  Label,
  CardTitle,
  FormGroup,
  CardFooter
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import Activity from './Activity';
// import CardUser from './CardUser';
// import Comments from './Comments';
// import PopularPost from './PoplularPost';
// import Posts from './Posts';
// import { MarkdownProvider } from './Markdown';

import SnailEditor from '../EditorComponent/SnailEditor';
import Settings from './Settings';
// import TopVisitors from './TopVisitors';

const DashboardBlog = () => {
  document.title = "数据库全文检索 | Snail";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Snail" breadcrumbItem="数据库全文检索" />

          <Row>
            <Col xl={8}>
              {/* <SnailEditor wsClient={wsClientRef.current} /> */}
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap align-items-start">
                    {/* <h5 className="card-title mb-3 me-2">搜索结果</h5> */}

                    {/* <UncontrolledDropdown className="ms-auto">
                      <DropdownToggle tag="a" className="text-muted font-size-16" role="button">
                        <i className="mdi mdi-dots-horizontal"></i>
                      </DropdownToggle> */}

                      {/* <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem className="dropdown-item" href="#">Action</DropdownItem>
                        <DropdownItem className="dropdown-item" href="#">Another action</DropdownItem>
                        <DropdownItem className="dropdown-item" href="#">Something else here</DropdownItem>
                        <div className="dropdown-divider"></div>
                        <DropdownItem className="dropdown-item" href="#">Separated link</DropdownItem>
                      </DropdownMenu> */}
                    {/* </UncontrolledDropdown> */}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl={4}>
              <Settings />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DashboardBlog;
