import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import BlockNoteApp from './BlockNote';
import { useMarkdown} from './Markdown';
import { createContext, useContext, useState, useEffect } from 'react';

import WSClient from "../../helpers/websocket_client";

interface CardEditorProps {
  updateEditorAiCommand: (content: string) => void;
  gptAnswer: string;
}

// const CardEditor = ({ dataColors }: any) => {
const CardEditor: React.FC<CardEditorProps> = ({ updateEditorAiCommand, gptAnswer }) => {
const fileInputRef = React.useRef<HTMLInputElement>(null);
const {  importedContent, setImportedContent,
          // exportedContent, setExportedContent,
          exportedFlag, setExportedFlag,
          // exportFileCmd,
          fileExtension, setFileExtension
      } = useMarkdown();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const ext = file.name.split('.').pop() || "json";
      setFileExtension(ext);
      const reader = new FileReader();
      reader.onload = function(e) {
        const content = e.target?.result;
        if (typeof content === 'string') {
          setImportedContent(content); // 使用setMarkdown函数设置文件内容
          // console.log("File content:", content);
        } else {
          console.error("File content is not a string:", content);
        }
      };

      reader.readAsText(file);  // 读取文件内容为文本
    }
  };

  const handleImport = () => {
    // 触发 input 元素的点击事件
    fileInputRef.current?.click();
  };

  const handleExport = () => {
    // 你的导出逻辑
    console.log('Export clicked!');
    setFileExtension("md");
    setExportedFlag(true);
    // exportFileCmd();
  };

  const handleSave = () => {
    // 你的保存逻辑
    console.log('Save clicked!');
    setFileExtension("json");
    setExportedFlag(true);
    // exportFileCmd();
  };

  const handleSettings = () => {
    // 你的设置逻辑
    console.log('Settings clicked!');
  };

  return (
    // <React.Fragment>
      <Col xl={12}>

        {/* 隐藏的文件选择输入框 */}
        <input
            type="file"
            accept=".json,.md"
            style={{ display: 'none' }}
            ref={fileInputRef}
            onChange={handleFileChange}
        />

        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              {/* <h5 className="card-title me-2">矢量数据库</h5> */}
              <div className="ms-auto">
                <div className="toolbar d-flex flex-wrap gap-2 text-end">
                  <button type="button" className="btn btn-light btn-sm" onClick={handleImport}>
                    导入文本
                  </button>
                  <button type="button" className="btn btn-light btn-sm" onClick={handleExport}>
                    导出MD
                  </button>
                  <button type="button" className="btn btn-light btn-sm"  onClick={handleSave}>
                    导出JSON
                  </button>
                  {/* <button type="button" className="btn btn-light btn-sm" onClick={handleSettings}>
                    拆分
                  </button> */}
                  <button type="button" className="btn btn-light btn-sm active" onClick={handleSettings}>
                    保存到服务器
                  </button>
                </div>
              </div>
            </div>
            {/* <NoteApp markdownContent={markdown} /> */}
            <BlockNoteApp updateEditorAiCommand={updateEditorAiCommand} gptAnswer={gptAnswer} />

          </CardBody>
        </Card>
      </Col>
    // </React.Fragment>
  );
};



export default CardEditor;
