import React from 'react';
import EditorApp from './BlockNote';
import { Card, CardBody, Container } from "reactstrap";

import Breadcrumb from 'Components/Common/Breadcrumb';

// import Component
import FileLeftBar from "../FileManager/FileLeftBar";
import FileList from "../FileManager/FileList";
import RecentFile from "../FileManager/RecentFile";
import Storage from "../FileManager/Storage";

const Dashboard = () => {
  document.title = "Dashboards | Skote - React Admin & Dashboard Template";

  const series = [76]
  const options = {
    chart: {
      height: 150,
      type: "radialBar",
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },

        hollow: {
          size: "60%",
        },

        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "16px",
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    stroke: {
      dashArray: 3,
    },
    labels: ["Storage"],
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Dashboards" breadcrumbItem="Dashboards" />
          <div className="d-xl-flex">
          <div className="w-100">
            <div className="d-md-flex">
              {/* FileRightBar  */}
              <FileLeftBar />
              <div className="w-100">
                <Card>
                  <CardBody>
                    <FileList />
                    <RecentFile />
                    {/* <EditorApp></EditorApp> */}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <Storage options={options} series={series} />
        </div>
        </Container>
      </div>
    </React.Fragment >
  );
}

export default Dashboard;




