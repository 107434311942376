import React, { useCallback, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import Settings from './Settings';

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import classnames from "classnames";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar9 from "../../assets/images/users/avatar-9.jpg";
import avatar10 from "../../assets/images/users/avatar-10.jpg";
import avatar11 from "../../assets/images/users/avatar-11.jpg";
import avatar12 from "../../assets/images/users/avatar-12.jpg";
import SnailEditor from "../EditorComponent/SnailEditor"
import {
  getReadings as onGetChats,
  getGroups as onGetGroups,
  getContacts as onGetContacts,
  getMessages as onGetMessages,
  addMessage as onAddMessage,
  getNewDocId as onNewDocument,
} from "../../slices/chats/thunk"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

import WSClient from "../../helpers/websocket_client";
import { HTTP_URL, WS_URL } from '../../helpers/url_helper';  //dded WS_URL in url_helper.ts

const Chat = () => {

  //meta title
  document.title = "NPC Chat | Snail";

  const dispatch = useDispatch<any>();

  const selectProperties = createSelector(
    (state: any) => state.chats,
    (chats) => ({
      chats: chats.chats,
      groups: chats.groups,
      contacts: chats.contacts,
      messages: chats.messages,
      newDocid: chats.newDocid,
    })
  );

  const {
    chats,
    groups,
    contacts,
    messages,
    newDocid,
  } = useSelector(selectProperties);

const avatarMap = {
  "avatar-2": avatar2,
  "avatar-9": avatar9,
  "avatar-10": avatar10,
  "avatar-11": avatar11
};

  const getInitialRoomId = (): number => {
    const savedRoomId = Cookies.get('currentRoomId');
    return savedRoomId ? parseInt(savedRoomId, 10) : 69;
    // return 69;
  };

  const getInitialChatname = (): string => {
    const savedChatname = Cookies.get('currentChatname');
    return savedChatname ? savedChatname: "新的对话";
    // return 69;
  };

  const [messageBox, setMessageBox] = useState<any>(null);
  const [msgData, setMsgData] = useState<any>();
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(getInitialRoomId());

  // eslint-disable-next-line no-unused-vars
  const currentUser = {
    name: "Eric Lee",
    isActive: true,
  };

  const [curUserMsgID, setUserMsgID] = useState<any>(uuidv4());
  const [curBotMsgID, setBotMsgID] = useState<any>(uuidv4());

  const [menu1, setMenu1] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<any>("1");
  const [Chat_Box_Username, setChat_Box_Username] = useState<any>(getInitialChatname);
  // eslint-disable-next-line no-unused-vars
  const Chat_Box_User_Status = "Active Now";

  const [curMessage, setcurMessage] = useState<any>("");
  const [curAiMessage, setcurAiMessage] = useState<any>("");
  const [newAnswer, setNewAnswer] = useState<string>("");

  // 如果你想读取 cookie
  useEffect(() => {
    let savedRoomId = getInitialRoomId();
    setCurrentRoomId(savedRoomId);
  }, []);

  const wsClientRef = useRef<WSClient | null>(null);  // 使用 useRef 来保存 wsClient 的引用
  // useEffect(() => {
  //   wsClientRef.current = new WSClient(WS_URL, currentRoomId, dispatch);
  //   return () => {
  //       wsClientRef.current?.closeConnection();
  //   };
  // }, []);

  const handleWebsocketReconnect = () => {
    // 如果已经存在一个 WebSocket 连接，则先关闭它
    if (wsClientRef.current) {
      wsClientRef.current.closeConnection();
    }

    console.log("------------->new websocket RoomId = ", currentRoomId);
    // 重新创建 WebSocket 连接
    wsClientRef.current = new WSClient(WS_URL, currentRoomId, dispatch);
  };

  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetGroups());
    // dispatch(onGetContacts());
    dispatch(onGetMessages(currentRoomId));
    Cookies.set('currentRoomId', currentRoomId.toString());
    Cookies.set('currentChatname', Chat_Box_Username);
    // console.log("------------->current RoomId = ", currentRoomId);
    handleWebsocketReconnect();
  }, [
    dispatch,
    currentRoomId,
  ]
  );

  useEffect(() => {
    // 当newDocid变化时，这个效应会被触发

    // 这里执行其他基于newDocid变化的操作
    if(newDocid !== 0) {
      console.log("-> Updated newDocid:", newDocid.doc_id);
      setCurrentRoomId(newDocid.doc_id);
      setChat_Box_Username("开启一个新对话");
    }
  }, [newDocid]);

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages, scrollToBottom]);

  useEffect(() => {
    setMsgData(messages)
    const foundMessage = messages.find(message => message.id === curBotMsgID);
    if (foundMessage) {
      setNewAnswer(foundMessage.message);
    }
  }, [messages])

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  //Use For Chat Box
  const userChatOpen = (id: any, name: any, status: any, roomId: any) => {
    setChat_Box_Username(name);
    setCurrentRoomId(roomId);
    dispatch(onGetMessages(roomId));
  };

  const addMessage = (roomId: any, sender: any) => {
    const message = {
      id: curUserMsgID,
      roomId,
      sender,
      message: curMessage,
      createdAt: new Date(),
    };
    setcurMessage("");
    const newBotMsgId = uuidv4();
    setBotMsgID(newBotMsgId);

    // 使用 WebSocket 发送消息
    wsClientRef.current?.sendMessage(message, newBotMsgId);
    // setMsgID(prevMsgID => prevMsgID + 1);
    const newUserMsgId = uuidv4();
    setUserMsgID(newUserMsgId);
    dispatch(onAddMessage(message));
    scrollToBottom();
  };

  const addAiMessage = (roomId: any, sender: any) => {
    const message = {
      id: curUserMsgID,
      roomId,
      sender,
      message: curAiMessage,
      createdAt: new Date(),
    };
    setcurMessage("");
    const newBotMsgId = uuidv4();
    setBotMsgID(newBotMsgId);

    // 使用 WebSocket 发送消息
    wsClientRef.current?.sendMessage(message, newBotMsgId);
    // setMsgID(prevMsgID => prevMsgID + 1);
    const newUserMsgId = uuidv4();
    setUserMsgID(newUserMsgId);
    dispatch(onAddMessage(message));
    scrollToBottom();
  };

  useEffect(() => {
    if(curAiMessage) {
      // console.log("------new Ai Message-------> ", curAiMessage);
      addAiMessage(currentRoomId, currentUser.name);
    }
  }, [curAiMessage]);

  const sendAiCommand = (aicmd: string) => {
    // console.log("------new message aicmd-------> ", aicmd);
    setcurAiMessage(aicmd);
  };

  const onKeyPress = (e: any) => {
    const { key, value } = e;

    if (key === "Enter") {
      setcurMessage(value);
      // console.log("------new message------->current RoomId = ", currentRoomId);
      addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  const searchUsers = () => {
    var input: any, filter: any, ul: any, li: any, a: any, i: any, txtValue: any;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const [deleteMsg, setDeleteMsg] = useState<any>("");
  const toggle_deleMsg = (ele: any) => {
    setDeleteMsg(!deleteMsg);
    ele.closest("li").remove();
  };

  const copyMsg = (ele: any) => {
    var copyText = ele.closest(".conversation-list").querySelector("p").innerHTML;
    // navigator.clipboard.writeText(copyText);
    copyToClipboard(copyText);
  };


  const handleSearch = (ele: any) => {
    let search = ele.target.value;

    if (search) {
      setMsgData(messages?.filter((data: any) => data?.sender?.toLowerCase().includes(search)))
    } else {
      setMsgData(messages)
    }
  }

  const copyToClipboard = useCallback((textToCopy: string) => {
    // navigator clipboard 需要https等安全上下文
    if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(textToCopy);
    } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = "0";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise<void>((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand('copy') ? res() : rej(new Error("Failed to copy text"));
            textArea.remove();
        });
    }
}, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Snail" breadcrumbItem="知识库问答测试" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div >
                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">对话列表</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2");
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">新建对话</span>
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3");
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Contacts</span>
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            {/* <h5 className="font-size-14 mb-3">Recent</h5> */}
                            <ul className="list-unstyled chat-list" id="recent-list">
                              <PerfectScrollbar style={{ height: "540px" }}>
                                {map(chats, chat => (
                                  <li
                                    key={chat.id + chat.status}
                                    className={
                                      currentRoomId === chat.roomId
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        if (chat.roomId !== currentRoomId) {
                                          userChatOpen(
                                            chat.id,
                                            chat.name,
                                            chat.status,
                                            chat.roomId
                                          );
                                        }
                                      }}
                                    >
                                      <div className="d-flex">
                                        {/* <div className="align-self-center me-3">
                                          <i
                                            className={
                                              chat.status === "online"
                                                ? "mdi mdi-circle text-success font-size-10"
                                                : chat.status === "intermediate"
                                                  ? "mdi mdi-circle text-warning font-size-10"
                                                  : "mdi mdi-circle font-size-10"
                                            }
                                          />
                                        </div> */}
                                        {chat.isImg ?
                                          <div className="avatar-xs align-self-center me-3">
                                            <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                              {chat.profile}
                                            </span>
                                          </div>
                                          :
                                          <div className="align-self-center me-3">
                                            <img
                                              src= {avatarMap[chat.image]}//{avatar9}
                                              className="rounded-circle avatar-xs"
                                              alt=""
                                            />
                                          </div>
                                        }

                                        <div className="flex-grow-1 overflow-hidden">
                                          <h5 className="text-truncate font-size-14 mb-1">
                                            {chat.name}
                                          </h5>
                                          <p className="text-truncate mb-0">
                                            {chat.description}
                                          </p>
                                        </div>
                                        <div className="font-size-11">
                                          {chat.time}
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                              </PerfectScrollbar>
                            </ul>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          {/* <h5 className="font-size-14 mb-3">Group</h5> */}
                          <ul className="list-unstyled chat-list">
                            <PerfectScrollbar style={{ height: "540px" }}>
                              {groups &&
                                (groups || []).map((group: any) => (
                                  <li key={"test" + group.image}>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        dispatch(onNewDocument(group.id));
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-xs me-3">
                                          <img
                                            src= {avatarMap[group.image]}
                                            className="rounded-circle avatar-xs"
                                            alt=""
                                          />
                                        </div>

                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14 mb-0">
                                            {group.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </TabPane>

                        {/* <TabPane tabId="3">
                          <h5 className="font-size-14 mb-3">Contact</h5>

                          <div>
                            <PerfectScrollbar style={{ height: "410px" }}>
                              {contacts &&
                                (contacts || []).map((contact: any) => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map((array: any) => (
                                        <li key={"test" + array.id}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              // userChatOpen(
                                              //   array.id,
                                              //   array.name,
                                              //   array.status,
                                              //   Math.floor(Math.random() * 100)
                                              // );
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </PerfectScrollbar>
                          </div>
                        </TabPane> */}
                      </TabContent>
                    </div>
                  </div>
                </div>

                {/* <Col md={10} xs={10}> */}
                <Col md={7} xs={9}>
                    <SnailEditor updateEditorAiCommand={sendAiCommand} gptAnswer={newAnswer}/>
                 </Col>

                {/* <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md={4} xs={9}>
                          <h5 className="font-size-15 mb-1">
                            {Chat_Box_Username}
                          </h5>

                          <p className="text-muted mb-0">
                            <i
                              className={
                                Chat_Box_User_Status === "Active Now"
                                  ? "mdi mdi-circle text-success align-middle me-2"
                                  : Chat_Box_User_Status === "intermediate"
                                    ? "mdi mdi-circle text-warning align-middle me-1"
                                    : "mdi mdi-circle align-middle me-1"
                              }
                            />
                            {Chat_Box_User_Status}
                          </p>
                        </Col>
                        <Col md={8} xs={3}>
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown
                                className="me-1"
                                isOpen={search_Menu}
                                toggle={toggleSearch}
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-search-alt-2" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-md"
                                >
                                  <Form className="p-3">
                                    <FormGroup className="m-0">
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          placeholder="Search ..."
                                          aria-label="Recipient's username"
                                          onChange={handleSearch}
                                        />
                                        <Button color="primary" type="submit">
                                          <i className="mdi mdi-magnify" />
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>
                                  </Form>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown
                                isOpen={settings_Menu}
                                toggle={toggleSettings}
                                className="me-1"
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-cog" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="#">
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Clear chat
                                  </DropdownItem>
                                  <DropdownItem href="#">Rename</DropdownItem>
                                  <DropdownItem href="#">Delete</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item">
                              <Dropdown
                                isOpen={other_Menu}
                                toggle={toggleOther}
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-dots-horizontal-rounded" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#">Action</DropdownItem>
                                  <DropdownItem href="#">
                                    Another Action
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Something else
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <div className="chat-conversation p-3">
                        <ul className="list-unstyled">
                          <PerfectScrollbar
                            style={{ height: "470px" }}
                            containerRef={ref => setMessageBox(ref)}
                          >
                            <li>
                              <div className="chat-day-title">
                                <span className="title">Today</span>
                              </div>
                            </li>
                            {msgData &&
                              // map(msgData, message => (
                              map(msgData.slice(-10), message => (
                                <li
                                  key={"test_k" + message.id}
                                  className={
                                    message.sender === currentUser.name
                                      ? "right"
                                      : ""
                                  }
                                >
                                  <div className="conversation-list">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        href="#"
                                        tag="a" className="dropdown-toggle"
                                      >
                                        <i className="bx bx-dots-vertical-rounded" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem onClick={(e) => copyMsg(e.target)} href="#">
                                          Copy
                                        </DropdownItem>
                                        <DropdownItem href="#">
                                          Save
                                        </DropdownItem>
                                        <DropdownItem href="#">
                                          Forward
                                        </DropdownItem>
                                        <DropdownItem onClick={(e) => toggle_deleMsg(e.target)} href="#">
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <div className="ctext-wrap">
                                      <div className="conversation-name">
                                        {message.sender}
                                      </div>
                                      <p style={{ whiteSpace: 'pre-line' }}>{message.message}</p>
                                      <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1"></i> {message.time}</p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                          </PerfectScrollbar>
                        </ul>
                      </div>
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <Input
                                type="text"
                                value={curMessage}
                                onKeyPress={onKeyPress}
                                onChange={e => setcurMessage(e.target.value)}
                                className="chat-input"
                                placeholder="Enter Message..."
                              />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-image-outline me-1"
                                        id="Imagetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Imagetooltip"
                                      >
                                        Images
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-document-outline"
                                        id="Filetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Filetooltip"
                                      >
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={() =>
                                addMessage(currentRoomId, currentUser.name)
                              }
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div> */}


                <Col xl={3}>
                  <Settings />
                </Col>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



export default Chat;
