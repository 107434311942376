"use client"; // this registers <Editor> as a Client Component

import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { MdDelete } from "react-icons/md";
import "@blocknote/core/style.css";
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMarkdown } from "./Markdown";
import WSClient from "../../helpers/websocket_client";

import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import {
    BlockNoteView,
    FormattingToolbarPositioner,
    HyperlinkToolbarPositioner,
    SideMenuPositioner,
    SlashMenuPositioner,
    ToggledStyleButton,
    Toolbar,
    ToolbarButton,
    useBlockNote,
    useEditorContentChange,
    useEditorSelectionChange,
    getDefaultReactSlashMenuItems,
    ReactSlashMenuItem,
    DragHandle,
    SideMenu,
    SideMenuButton,
    SideMenuProps,
    DefaultSideMenu,
    DragHandleMenu,
    DragHandleMenuItem,
    RemoveBlockButton,
  } from "@blocknote/react";

//  Gets the previously stored editor contents.
const initialContent: string | null = localStorage.getItem("editorContent");
interface BlockNoteAppProps {
  markdownContent: string;
}

const insertNewBlcok = (editor: BlockNoteEditor) => {
  // Block that the text cursor is currently in.
  // const currentBlock: Block = editor.getTextCursorPosition().block;
  // console.log("ref block id:", currentBlock.id);

  let currentBlock: Block; // 在函数作用域内声明currentBlock变量

  const blocks = editor.getSelection()?.blocks;
  if (blocks && blocks.length > 0) {
    console.log("blocks:", blocks);
    // currentBlock = blocks[blocks.length - 1];
    currentBlock = blocks[blocks.length - 1];
  } else {
    currentBlock = editor.getTextCursorPosition().block;
  }

  // console.log("ref block id:", currentBlock.id);

  // New block we want to insert.
  const helloWorldBlock: PartialBlock = {
    type: "paragraph",
    // content: [{ type: "text", text: "", styles: { bold: true } }],
    content: [{ type: "text", text: "", styles: { textColor: "brown" } }],
  };

  // Inserting the new block after the current one.
  editor.setTextCursorPosition(currentBlock);
  editor.insertBlocks([helloWorldBlock], currentBlock, "after");
};

const updateBlock_GPTAnswer = (editor: BlockNoteEditor, answer: string ) => {
  const newBlock: Block|undefined = editor.getTextCursorPosition().nextBlock;
  if(newBlock !== undefined) {
    editor.updateBlock(newBlock, {
      type: "paragraph",
      content: [{ type: "text", text: answer, styles: { textColor: "brown" } }],
    });
  }
};

// Command to insert "Hello World" in bold in a new block below.
// const handleAskAI = (editor: BlockNoteEditor, questionType: string) => {
const handleAskAI = (editor: BlockNoteEditor, questionType: string, updateEditorAiCommand: (content: string) => void) => {
  // AskQuestion  Summary Translate   ExpandContent
  const aiCmd = `[[[${questionType}]]] <<<${editor.getSelectedText()}>>>`;
  updateEditorAiCommand(aiCmd);

  // New block we want to insert.
  insertNewBlcok(editor);
};

//customize tools
//const CustomFormattingToolbar = (props: { editor: BlockNoteEditor }) => {
const CustomFormattingToolbar = (props: { editor: BlockNoteEditor, updateEditorAiCommand: (content: string) => void }) => {
  // Tracks whether the text & background are both blue.
  const [isSelected, setIsSelected] = useState<boolean>(
    props.editor.getActiveStyles().textColor === "blue" &&
      props.editor.getActiveStyles().backgroundColor === "blue"
  );

  // Updates state on content change.
  useEditorContentChange(props.editor, () => {
    setIsSelected(
      props.editor.getActiveStyles().textColor === "blue" &&
        props.editor.getActiveStyles().backgroundColor === "blue"
    );
  });

  // Updates state on selection change.
  useEditorSelectionChange(props.editor, () => {
    setIsSelected(
      props.editor.getActiveStyles().textColor === "blue" &&
        props.editor.getActiveStyles().backgroundColor === "blue"
    );
  });

  // "翻译"  "总结"  "大纲"  "提问"  "扩写"  "润色"
  // "讲笑话"  "标重点"   "查错"  "速读"  "提炼"  "标题分析"
  // AskQuestion  Summary Translate   ExpandContent
  return (
    <Toolbar>
      {/*Default button to toggle bold.*/}
      <ToggledStyleButton editor={props.editor} toggledStyle={"bold"} />
      {/*Default button to toggle italic.*/}
      <ToggledStyleButton editor={props.editor} toggledStyle={"italic"} />
      {/*Default button to toggle underline.*/}
      <ToggledStyleButton editor={props.editor} toggledStyle={"underline"} />
      {/*Custom button to toggle blue text & background color.*/}
      <ToolbarButton
        mainTooltip={"所有内容直传给GPT"}
        onClick={() => handleAskAI(props.editor, "AskGPT", props.updateEditorAiCommand)}>
        AskGPT
      </ToolbarButton>
      <ToolbarButton
        mainTooltip={"总结"}
        onClick={() => handleAskAI(props.editor, "总结", props.updateEditorAiCommand)}>
        总结
      </ToolbarButton>
      <ToolbarButton
        mainTooltip={"基于选中的文字进行扩充"}
        onClick={() => handleAskAI(props.editor, "扩写", props.updateEditorAiCommand)}>
        扩写
      </ToolbarButton>
      <ToolbarButton
        mainTooltip={"使用高级字词来重写文本"}
        onClick={() => handleAskAI(props.editor, "润色", props.updateEditorAiCommand)}>
        润色
      </ToolbarButton>
      <ToolbarButton
        mainTooltip={"自动进行中英文互译"}
        onClick={() => handleAskAI(props.editor, "翻译", props.updateEditorAiCommand)}>
        翻译
      </ToolbarButton>
    </Toolbar>
  );
};

// Command to insert "Hello World" in bold in a new block below.
const insertQuestBlock = (editor: BlockNoteEditor) => {
  // Block that the text cursor is currently in.
  const currentBlock: Block = editor.getTextCursorPosition().block;

  // New block we want to insert.
  const helloWorldBlock: PartialBlock = {
    type: "paragraph",
    content: [{ type: "text", text: "Question: ", styles: { bold: true } }],
    // content: [{ type: "text", text: "Question: ", styles: { textColor: "red" } }],
  };

  // Inserting the new block after the current one.
  editor.insertBlocks([helloWorldBlock], currentBlock, "after");

  const newBlock: Block|undefined = editor.getTextCursorPosition().nextBlock;
  if(newBlock !== undefined) {
    console.log("curr Block Id =", newBlock.id);
    editor.setTextCursorPosition(newBlock, "end");
  }
};

// Custom Slash Menu item which executes the above function.
const insertHelloWorldItem: ReactSlashMenuItem = {
  name: "Ask GPT",
  execute: insertQuestBlock,
  aliases: ["helloworld", "hw"],
  group: "AI",
  icon: <HiOutlineGlobeAlt size={18} />,
  hint: "Send question to GPT and get Answers.",
};

// List containing all default Slash Menu Items, as well as our custom one.
const customSlashMenuItemList = [
  insertHelloWorldItem,
  ...getDefaultReactSlashMenuItems(),

];

// const CustomSideMenu = (props: SideMenuProps) => (
//   <SideMenu>
//     {/* <SideMenuButton>
//       <MdDelete
//         size={24}
//         onClick={() => props.editor.removeBlocks([props.block])}
//       />
//     </SideMenuButton> */}
//     <DragHandle {...props} />
//   </SideMenu>
// );


// const CustomDragHandleMenu = (props: {
//     editor: BlockNoteEditor;
//     block: Block;
//   }) => {
//     return (
//       <DragHandleMenu>
//         {/*Default item to remove the block.*/}
//         <RemoveBlockButton {...props}>删除</RemoveBlockButton>
//         {/*Custom item which opens an alert when clicked.*/}
//         <DragHandleMenuItem onClick={() => window.alert("Button Pressed!")}>
//           翻译
//         </DragHandleMenuItem>
//         <DragHandleMenuItem onClick={() => window.alert("Button Pressed!")}>
//           检索
//         </DragHandleMenuItem>
//         <DragHandleMenuItem onClick={() => window.alert("Button Pressed!")}>
//           润色
//         </DragHandleMenuItem>
//         <DragHandleMenuItem onClick={() => window.alert("Button Pressed!")}>
//           Alert
//         </DragHandleMenuItem>
//       </DragHandleMenu>
//     );
//   };

// const cycleBlocksShortcut = (event: KeyboardEvent, editor: BlockNoteEditor, setNewAiMessage: React.Dispatch<React.SetStateAction<any>>,
const cycleBlocksShortcut = (event: KeyboardEvent, editor: BlockNoteEditor, updateEditorAiCommand: (content: string) => void ) => {
  if (event.ctrlKey && event.key === 'Enter') {
    // 执行Ctrl+Enter按下时的操作
    console.log('Ctrl+Enter was pressed!');

    const hoveredBlock1: Block = editor.getTextCursorPosition().block;
    // 检查hoveredBlock1.content是否存在并且是一个数组
    if (Array.isArray(hoveredBlock1.content)) {
      // 使用map方法来处理每一项的text，并使用join方法将结果拼接起来
      let newQuestion = hoveredBlock1.content
        .map(item => {
          if ('text' in item) {
            let text = item.text;
            // 如果text以"Question:"开头，去掉它
            if (text.startsWith("Question:")) {
              text = text.replace("Question:", "").trim();
            }
            // 去掉text末尾的换行符
            text = text.replace(/\n$/, "");
            return text;
          }
          return '';
        })
        .join('');

      console.log(newQuestion);

      editor.updateBlock(hoveredBlock1, {
        type: "paragraph",
        content: [{ type: "text", text: "Q: "+ newQuestion, styles: { bold: true } }],
      });

      updateEditorAiCommand(newQuestion);
      // 插入新的block
      insertNewBlcok(editor);
    }
  }

  // Checks for Ctrl+G shortcut
  else if (event.ctrlKey && event.key === "g") {
    // Needs type cast as Object.keys doesn't preserve type
    const allBlockTypes: Block["type"][] = Object.keys(
      editor.schema
    ) as Block["type"][];

    const currentBlockType: Block["type"] =
      editor.getTextCursorPosition().block.type;

    const nextBlockType: Block["type"] =
      allBlockTypes[
        (allBlockTypes.indexOf(currentBlockType) + 1) % allBlockTypes.length
      ];

    editor.updateBlock(editor.getTextCursorPosition().block, {
      type: nextBlockType,
    });
  }
};

interface EditorSocketProps {
  updateEditorAiCommand: (content: string) => void;
  gptAnswer: string;
}

const BlockNoteApp : React.FC<EditorSocketProps> = ({ updateEditorAiCommand, gptAnswer }) => {
  const {  importedContent, setImportedContent,
            // exportedContent, setExportedContent,
            exportedFlag, setExportedFlag,
            // exportFileCmd,
            fileExtension, setFileExtension
        } = useMarkdown();
  const [editorContent, setEditorContent] = useState<string>("");
  // const [search_Menu, setsearch_Menu] = useState<boolean>(false);

  // const sendUserQuestion = () => {
  //   console.log("==========new Message:", newAiMessage);

  //   // 使用updateEditorAiCommand发送消息
  //   updateEditorAiCommand(newAiMessage);

  //   // 如果需要，可以在发送消息后清空newAiMessage状态
  //   setNewAiMessage("");
  // };

  // const onKeyPress = (e: any) => {
  //   const { key, value } = e;

  //   if (key === "Enter") {
  //     setNewAiMessage(value);
  //     sendUserQuestion();
  //   }
  // };

  // const handleSearch = (ele: any) => {
  //   let search = ele.target.value;

  // }
  //   //Toggle Chat Box Menus
  // const toggleSearch = () => {
  //   setsearch_Menu(!search_Menu);
  // };

  // Creates a new editor instance.
  const editor: BlockNoteEditor = useBlockNote({
        // Makes the editor non-editable.
    slashMenuItems: customSlashMenuItemList,
    // If the editor contents were previously saved, restores them.
    initialContent: initialContent ? JSON.parse(initialContent) : undefined,
    // initialContent: importedContent ? importedContent : (initialContent ? JSON.parse(initialContent) : undefined),
    // Serializes and saves the editor contents to local storage.
    onEditorContentChange: (editor) => {
      // Get and log all top-level, i.e. non-nested blocks in the editor.
      const blocks = editor.topLevelBlocks;
      const content = JSON.stringify(blocks);
      // console.log("Content was changed:", blocks);
      setEditorContent(content);
      localStorage.setItem("editorContent", content);

      // Log the document to console on every update
      // console.log(editor.getJSON());
    },

    // Listens for when the text cursor position changes.
    onTextCursorPositionChange: (editor) => {
      // Gets the block currently hovered by the text cursor.
      const hoveredBlock2: Block = editor.getTextCursorPosition().block;

      // Traverses all blocks.
      // editor.forEachBlock((block) => {
      //   if (
      //     block.id === hoveredBlock2.id &&
      //     block.props.backgroundColor !== "blue"
      //   ) {
      //     // If the block is currently hovered by the text cursor, makes its
      //     // background blue if it isn't already.
      //     editor.updateBlock(block, {
      //       props: {backgroundColor: "blue"},
      //     });
      //   } else if (
      //     block.id !== hoveredBlock2.id &&
      //     block.props.backgroundColor === "blue"
      //   ) {
      //     // If the block is not currently hovered by the text cursor, resets
      //     // its background if it's blue.
      //     editor.updateBlock(block, {
      //       props: {backgroundColor: "default"},
      //     });
      //   }

      //   return true;
      // });
    },

    // Adds event handler on key down when the editor is ready
    onEditorReady: (editor) =>
      editor.domElement.addEventListener("keydown", (event) =>
        cycleBlocksShortcut(event, editor, updateEditorAiCommand)
      ),
    });

    // 使用 useEffect 来监听 gptAnswer 的更改
    useEffect(() => {
      if (editor && gptAnswer) { // 确保 editorInstance 已初始化并且 gptAnswer 有内容
        updateBlock_GPTAnswer(editor, gptAnswer);
      }
    }, [gptAnswer, editor]);

    useEffect(() => {
      // if (editor && importedContent) {
      if (importedContent) {
        // // Whenever the current importedContent content changes, converts it to an array
        // // of Block objects and replaces the editor's content with them.
        const getBlocks = async () => {
          if(fileExtension === 'md') {
            const blocks: Block[] = await editor.markdownToBlocks(importedContent);
            // console.log("imported Markdown Block:", blocks);
            editor.replaceBlocks(editor.topLevelBlocks, blocks);
          }
          else
          {
            const blocks: Block[] = JSON.parse(importedContent);
            // console.log("imported JSON Block:", blocks);
            editor.removeBlocks(editor.topLevelBlocks);
            const nullBlock: Block = editor.getTextCursorPosition().block;
            editor.insertBlocks(blocks, nullBlock, "before")
            // editor.replaceBlocks(editor.topLevelBlocks, blocks);
          }
        };
        getBlocks();
      }
    }, [editor, importedContent]);

    useEffect(() => {
      const exportFile = async () => {
        if (exportedFlag) {
          const blocks = editor.topLevelBlocks;
          // console.log("Content was changed:", fileExtension);

          let content: string;
          if (fileExtension === 'md') {
            content = await editor.blocksToMarkdown(blocks);
          } else {
            content = JSON.stringify(blocks);
          }

          // 现在直接在这里执行导出操作，使用刚刚计算的内容
          let mimeType = 'text/plain';
          let fileExtensionWithDot = '.json'; // 默认扩展名

          if (fileExtension === 'md') {
            mimeType = 'text/markdown';
            fileExtensionWithDot = '.md';
          } else if (fileExtension === 'json') {
            mimeType = 'application/json';
            fileExtensionWithDot = '.json';
          }

          // console.log("File Export Content was changed:", content);
          const blob = new Blob([content], { type: mimeType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'document_temp' + fileExtensionWithDot; // 使用对应的文件扩展名
          a.click();
          window.URL.revokeObjectURL(url);
          setExportedFlag(false);
        }
      };

      exportFile();
    }, [editor, editorContent, fileExtension, exportedFlag]);


  return (
    <div>
      <BlockNoteView editor={editor} theme={"light"}>
        <FormattingToolbarPositioner
          editor={editor}
          // formattingToolbar={CustomFormattingToolbar}
          formattingToolbar={(props) => <CustomFormattingToolbar {...props} updateEditorAiCommand={updateEditorAiCommand} />}
        />
        <HyperlinkToolbarPositioner editor={editor} />
        <SlashMenuPositioner editor={editor} />

        {/* <SideMenuPositioner editor={editor} sideMenu={CustomSideMenu} /> */}
        <SideMenuPositioner
              editor={editor}
              sideMenu={(props) => (
                  // <DefaultSideMenu {...props} dragHandleMenu={CustomDragHandleMenu} />
                  <DefaultSideMenu {...props} />
              )}
          />
      </BlockNoteView>

        {/* <InputGroup>
          <Input
            type="text"
            value={newAiMessage}
            onKeyPress={onKeyPress}
            onChange={e => setNewAiMessage(e.target.value)}
            placeholder="Enter Message..."
            aria-label="Recipient's username"
          />

          <Button
            type="button"
            color="primary"
            onClick={() =>
              sendUserQuestion()
            }
            >
            <i className="mdi mdi-send" />
          </Button>
        </InputGroup> */}

    </div>
    );
  }

export default BlockNoteApp;
  // export function getEditorMarkdown(editor: BlockNoteEditor): string {
  //   return editor.blocksToMarkdown(editor.topLevelBlocks);
// }
// const markdown: string =
// await editor.blocksToMarkdown(editor.topLevelBlocks);
// setMarkdown(markdown);

// const editor = new BlockNoteEditor({
//     element: document.getElementById("root")!,
//     uiFactories: {
//       formattingToolbarFactory: customFormattingToolbarFactory,
//       hyperlinkToolbarFactory: customHyperlinkToolbarFactory,
//       slashMenuFactory: customSlashMenuFactory,
//       blockSideMenuFactory: customBlockSideMenuFactory,
//     }
//   });