import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

type EditorContextType = {
  importedContent: string;
  setImportedContent: React.Dispatch<React.SetStateAction<string>>;
  // exportedContent: string;
  // setExportedContent: React.Dispatch<React.SetStateAction<string>>;
  exportedFlag: boolean;
  setExportedFlag: React.Dispatch<React.SetStateAction<boolean>>;
  // exportFileCmd: () => void;
  fileExtension: string;
  setFileExtension: React.Dispatch<React.SetStateAction<string>>;
};


// 创建一个新的 context
const EditorContext = createContext<EditorContextType | undefined>(undefined);

interface EditorProviderProps {
  children: React.ReactNode;
}

export const MarkdownProvider: React.FC<EditorProviderProps> = ({ children }) => {
  const [importedContent, setImportedContent] = useState<string>("");
  // const [exportedContent, setExportedContent] = useState<string>("");
  const [fileExtension, setFileExtension] = useState<string>("json");
  const [exportedFlag, setExportedFlag] = useState<boolean>(false);


// useEffect(() => {
//   const exportFile = () => {
// const exportFile = useCallback(() => {

  // const exportFileCmd = () => {
  //   // 根据 fileExtension 设置 MIME 类型和文件扩展名
  //   let mimeType = 'text/plain';
  //   let fileExtensionWithDot = '.json'; // 默认扩展名

  //   if (fileExtension === 'md') {
  //     // setFileExtension("md");
  //     mimeType = 'text/markdown';
  //     fileExtensionWithDot = '.md';
  //   }
  //   else if (fileExtension === 'json') {
  //       // setFileExtension("json");
  //       mimeType = 'application/json';
  //       fileExtensionWithDot = '.json';
  //   }

  //   console.log("File Export Content was changed:", exportedContent);
  //   const blob = new Blob([exportedContent], { type: mimeType });
  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement('a');
  //   a.href = url;
  //   a.download = 'document_temp' + fileExtensionWithDot; // 使用对应的文件扩展名
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  //   setExportedFlag(false);
  // };
// }, [exportedContent, fileExtension]);

// const exportFileCmd = () => {
//   exportFile();
// }



  return (
    <EditorContext.Provider
      value={{ importedContent, setImportedContent,
              //  exportedContent, setExportedContent,
               exportedFlag, setExportedFlag,
              //  exportFileCmd,
               fileExtension, setFileExtension }}>
      {children}
    </EditorContext.Provider>
  );
};

// 创建一个 hook 以便容易地访问 context
export const useMarkdown = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error("useMarkdown must be used within a MarkdownProvider");
  }
  return context;
};
