import React from 'react'
import { Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
// import Activity from './Activity';
import CardEditor from './CardEditor';
// import Comments from './Comments';
// import PopularPost from './PoplularPost';
// import Posts from './Posts';
import { MarkdownProvider } from './Markdown';

// import Settings from './Settings';
// import TopVisitors from './TopVisitors';
import WSClient from "../../helpers/websocket_client";

interface SnailEditorProps {
  updateEditorAiCommand: (content: string) => void;
  gptAnswer: string;
}

// const SnailEditor = () => {
const SnailEditor: React.FC<SnailEditorProps> = ({ updateEditorAiCommand, gptAnswer }) => {

  document.title = "创作助手 | Snail";

  // const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   updateEditorAiCommand(event.target.value);
  // };

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
        {/* <Container fluid> */}
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Snail" breadcrumbItem="建立知识库" /> */}

          <Row>
            <MarkdownProvider>
              <CardEditor updateEditorAiCommand={updateEditorAiCommand} gptAnswer={gptAnswer}/>
            </MarkdownProvider>
          </Row>
        {/* </Container> */}
      {/* </div> */}
    </React.Fragment>
  )
}

export default SnailEditor;
