import { stringify } from 'querystring';
import ReconnectingWebSocket, { CloseEvent, ErrorEvent, Event } from 'reconnecting-websocket';
// import MessageEvent from "reconnecting-websocket";
import {
    updateGptAnswer as onUpdateAnswer,
    addMessage as onAddMessage,
  } from "../slices/chats/thunk"

class WSClient {
    private ws: ReconnectingWebSocket;
    private readonly url: string;
    private readonly document_id: number;
    private innerText: string = "";

    private dispatch: any;
    private message: any;
    private answerMsgId: any;

    constructor(url: string, document_id: number, dispatch: any) {
    // constructor(url: string, document_id: string) {
    // constructor() {
        this.url = url;
        this.document_id = document_id;
        this.ws = new ReconnectingWebSocket(this.url+document_id);

        this.ws.onopen = this.onOpen.bind(this);
        this.ws.onmessage = this.onMessage.bind(this);
        this.ws.onerror = this.onError.bind(this);
        this.ws.onclose = this.onClose.bind(this);


        this.dispatch = dispatch;  // 添加这行
        this.message = "";
    }

    // WebSocket event handlers
    private onOpen(event: Event) {
        console.log('WebSocket connection opened:', event);
        this.ws.send(JSON.stringify({
            'type': 'initialize',
            'document_id': this.document_id
        }));
    }

    private onMessage(event: MessageEvent) {
        // console.log('WebSocket message received:', event.data);
        // You can also parse and handle the message data here
        this.websocketHandler(event);
    }

    private onError(event: ErrorEvent) {
        console.error('WebSocket error:', event);
    }

    private onClose(event: CloseEvent) {
        console.log('WebSocket connection closed:', event);
    }

    public closeConnection() {
        this.ws.close();
    }

    // Public methods to interact with the WebSocket
    public sendMessage(message: any, newMsgId: any) {
        if (this.ws.readyState === this.ws.OPEN) {
            //this message is an object, NOT IS text msg
            this.message = message;
            if(message.message != null && message.message.trim() != "")
            {
                // console.log('WebSocket send message:', message);
                this.ws.send(JSON.stringify({
                    'type': 'content',
                    'new_question': message.message
                }));
                this.answerMsgId = newMsgId;
            }
        } else {
            console.error('WebSocket is not open. Ready state:', this.ws.readyState);
        }
    }

    private addNewAnswer(new_token: string): void {
        this.innerText += new_token;
        // console.log(this.innerText);
        this.message.message = this.innerText;
        // console.log("web socket get object:", this.message);
        this.dispatch(onUpdateAnswer(this.message));  // 在这里分派action
    }

    private websocketHandler(event: MessageEvent) {
        // 如果event.data不存在或不是字符串，则直接返回
        if (typeof event.data !== 'string') return;

        const data = JSON.parse(event.data);

        if (data.sender === "bot") {
            switch (data.type) {
                case "start":
                    this.message.id = this.answerMsgId;
                    this.message.message = "";
                    this.message.time = "now";
                    this.message.sender = "Snail Bot"
                    // console.log("web socket add new object:", this.message);
                    this.dispatch(onAddMessage(this.message));// 在这里分派action
                    break;

                case "stream":
                    this.addNewAnswer(data.message);
                    break;

                case "info":
                    // 处理逻辑...
                    break;

                case "end":
                    this.innerText = "";
                    // const sendButton = document.getElementById('submit-question-button');
                    // if (sendButton) {
                    //     sendButton.innerHTML = '<i class="mdi mdi-send"></i>';
                    //     sendButton.disabled = false;
                    // }
                    // 假设有一个counter变量，但需要在类级别定义

                    // counter++;
                    break;

                case "error":
                    // 错误处理逻辑...
                    break;

                default:
                    // 默认处理逻辑...
                    break;
            }
        } else {
            // 其他sender的处理逻辑...
        }
    }
}

export default WSClient;
