import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  DropdownItem,
  InputGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  Table,
  Label,
  CardTitle,
  FormGroup,
  CardFooter
} from "reactstrap"

import classnames from "classnames"
import EditorApp from './BlockEditor';
// import avatar from "../../assets/images/users/avatar-1.jpg"
//Simple bar
import SimpleBar from "simplebar-react"
// import ChatBox from './Chat';

const Settings = () => {
  const [activeTab, setActiveTab] = useState<any>("1")

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const [customchkPrimary, setcustomchkPrimary] = useState(true);
  const [customchkSuccess, setcustomchkSuccess] = useState(true);
  const [customchkInfo, setcustomchkInfo] = useState(true);
  const [customchkWarning, setcustomchkWarning] = useState(true);
  const [customchkDanger, setcustomchkDanger] = useState(true);
  const [customOutlinePrimary, setcustomOutlinePrimary] = useState(true);
  const [customOutlineSuccess, setcustomOutlineSuccess] = useState(true);
  const [customOutlineInfo, setcustomOutlineInfo] = useState(true);
  const [customOutlineWarning, setcustomOutlineWarning] = useState(true);
  const [customOutlineDanger, setcustomOutlineDanger] = useState(true);
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true);

  return (
    <React.Fragment>
      {/* <Col xl={4}> */}

      {/* <Card>
        <CardBody>
          <ChatBox />
        </CardBody>
      </Card> */}

        <Card>
          <CardBody>
            <div className="d-flex flex-wrap align-items-start">
              <h5 className="card-title mb-3 me-2">检索设置</h5>

              <UncontrolledDropdown className="ms-auto">
                <DropdownToggle tag="a" className="text-muted font-size-16" role="button">
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem className="dropdown-item" href="#">Action</DropdownItem>
                  <DropdownItem className="dropdown-item" href="#">Another action</DropdownItem>
                  <DropdownItem className="dropdown-item" href="#">Something else here</DropdownItem>
                  <div className="dropdown-divider"></div>
                  <DropdownItem className="dropdown-item" href="#">Separated link</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>

            {/* <div className="d-flex flex-wrap">
              <div>
                <p className="text-muted mb-1">Total Subscribe</p>
                <h4 className="mb-3">10,512</h4>
                <p className="text-success mb-0"><span>0.6 % <i className="mdi mdi-arrow-top-right ms-1"></i></span></p>
              </div>
              <div className="ms-auto align-self-end">
                <i className="bx bx-group display-4 text-light"></i>
              </div>
            </div> */}

            <Row className="mt-2">
              <Col lg={6}>
                <div className="mt-2">
                  <h5 className="font-size-14">搜索结果数量:</h5>
                  {/* <p className="card-title-desc">Range inputs have implicit values for min and
                    max—0 and 100, respectively.</p> */}
                  <input type="range" className="form-range" min="1" max="5" id="customRange2" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="mt-2">
                  <h5 className="font-size-14">相似性系数:</h5>
                  {/* <p className="card-title-desc">By default, range inputs “snap” to integer
                    values. To change this, you can specify a <code>step</code> value.</p> */}
                  <input type="range" className="form-range" min="0" max="1" id="customRange2" />
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
              <div className="mt-2">
                <Label>检索范围:</Label>
                <div className="control-group mb-2">
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="closeButton"
                      value="checked"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="closeButton"
                    >
                      本地知识库
                    </Label>
                  </div>

                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="debugInfo"
                      value="checked"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="debugInfo"
                    >
                      对话记录
                    </Label>
                  </div>

                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="progressBar"
                      value="checked"
                    />
                    <Label
                      className="form-check-label"
                      htmlFor="progressBar"
                    >
                      在线搜索
                    </Label>
                  </div>
                </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="control-group" id="toastTypeGroup">
                  <div className="controls mt-2">
                    <Label>检索工具:</Label>

                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        id="radio1"
                        name="toastType"
                        className="form-check-input"
                        value="success"
                        defaultChecked
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="radio1"
                      >
                        m3e-base
                      </Label>
                    </div>

                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        id="radio2"
                        name="toastType"
                        className="form-check-input"
                        value="info"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="radio2"
                      >
                        text2vec-base
                      </Label>
                    </div>

                    <div className="form-check mb-2">
                      <input
                        type="radio"
                        id="radio3"
                        name="toastType"
                        className="form-check-input"
                        value="warning"
                        disabled
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="radio3"
                      >
                        openai
                      </Label>
                    </div>

                    {/* <div className="form-check mb-2">
                      <input
                        type="radio"
                        id="radio4"
                        name="toastType"
                        className="form-check-input"
                        value="error"
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="radio4"
                      >
                        Error
                      </Label>
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <InputGroup className="bg-light rounded">
                <Input
                  // type="email"
                  className="bg-transparent border-0"
                  placeholder="Enter your keywords ..."
                  aria-label="Recipient's username"
                  aria-describedby="button-addon2"
                />

                <div className="input-group-append">
                  <button
                    className="btn btn-primary rounded"
                    type="button"
                    id="button-addon2"
                  >
                    <i className="bx bxs-paper-plane"></i>
                  </button>
                </div>
              </InputGroup>
            </Row>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle tag="h4" className="card-title mb-4">搜索结果</CardTitle>

            <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1")
                  }}
                >
                  Local DB
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2")
                  }}
                >
                  Online
                </NavLink>
              </NavItem>
            </Nav>

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "250px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle table-hover mb-0">
                    <tbody>
                      <tr>
                        <td style={{ width: "50px" }}>
                          <FormGroup check>
                            <Input
                              type="checkbox"
                              id="customCheck1"
                            />
                            <Label check for="customCheck1" />
                          </FormGroup>
                        </td>
                        <td >
                          <h5 className="text-truncate font-size-14 mb-1">
                            <Link to="#" className="text-dark">
                              Skote Saas Dashboard
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">Assigned to Mark</p>
                        </td>
                        {/* <td style={{ width: "90px" }}>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link to="#" className="text-success p-1">
                                  <i className="bx bxs-edit-alt" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="text-danger p-1">
                                  <i className="bx bxs-trash" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                      </tr>

                      <tr>
                        <td>
                          <FormGroup check>
                            <Input type="checkbox" id="customCheck2" />
                            <Label check for="customCheck2" />
                          </FormGroup>
                        </td>
                        <td>
                          <h5 className="text-truncate font-size-14 mb-1">
                            <Link to="#" className="text-dark">
                              New Landing UI
                            </Link>
                          </h5>
                          <p className="text-muted mb-0">Assigned to Team A</p>
                        </td>
                        {/* <td>
                          <div>
                            <ul className="list-inline mb-0 font-size-16">
                              <li className="list-inline-item">
                                <Link to="#" className="text-success p-1">
                                  <i className="bx bxs-edit-alt" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link to="#" className="text-danger p-1">
                                  <i className="bx bxs-trash" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </td> */}
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>
            </div>
          </CardBody>

          <CardFooter className="bg-transparent border-top">
            <div className="text-center">
              <Link to="#" className="btn btn-primary"> Send to GPT </Link>
            </div>
          </CardFooter>
        </Card>

      {/* </Col> */}
    </React.Fragment>
  )
}

export default Settings


